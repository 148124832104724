// ClipboardData.js
import React from 'react';

// Using hex strings instead of BigInt to avoid potential browser compatibility issues
const UINT80_MAX = '0xffffffffffffffffffff'; // 20 f's for uint80 max
const UINT80_MAX_MINUS_ONE = '0xfffffffffffffffffffe'; // max - 1

const NumberDropdown = ({ value, onChange, max, isTractor, isReturnDataIndex, isAdvancedPipe }) => {
  let displayValue = value;

  if (isReturnDataIndex && (value === UINT80_MAX || value === 'MAX')) {
    displayValue = 'MAX';
  } else if (isReturnDataIndex && (value === UINT80_MAX_MINUS_ONE || value === 'MAX-1')) {
    displayValue = 'MAX-1';
  } else if (isAdvancedPipe) {
    const n = Math.floor(parseInt(value) / 1000);
    displayValue = (parseInt(value) - n * 1000).toString();
  }

  const handleChange = (selectedValue) => {
    // Handle the special MAX cases for return data index
    if (isReturnDataIndex && selectedValue === 'MAX') {
      // Don't convert to BigInt, just use the hex string directly
      onChange(UINT80_MAX);
    } else if (isReturnDataIndex && selectedValue === 'MAX-1') {
      // Don't convert to BigInt, just use the hex string directly
      onChange(UINT80_MAX_MINUS_ONE);
    } else {
      onChange(selectedValue);
    }
  };

  return (
    <select
      value={displayValue}
      onChange={(e) => handleChange(e.target.value)}
      className="mx-3 p-2 rounded-md text-black bg-green-200 inline-block text-lg"
    >
      {[...Array(max + 1).keys()].map((num) => (
        <option key={num} value={num}>
          {num}
        </option>
      ))}
      {isTractor && isReturnDataIndex && (
        <>
          <option value="MAX">MAX</option>
          <option value="MAX-1">MAX-1</option>
        </>
      )}
    </select>
  );
};

const ClipboardData = ({
  clipboard,
  onClipboardChange,
  onClipboardDataChange,
  isTractor = false, // New prop to determine if it's used within a tractor
}) => {
  const showReturnPasteParams = () => {
    if (clipboard.typeId === '0x00') return null;

    return clipboard.returnPasteParams.map((data, dataIndex) => (
      <div key={`${dataIndex}`} className="mt-6 space-y-3 border-b border-gray-400 pb-6">
        <div className="text-lg text-gray-300 leading-relaxed flex flex-wrap items-center">
          {isTractor ? (
            <>
              <span>Copy the</span>
              <NumberDropdown
                value={data.returnDataIndex || 0}
                onChange={(value) => {
                  onClipboardDataChange(dataIndex, 'returnDataIndex', value);
                }}
                max={20}
                isTractor={isTractor}
                isReturnDataIndex={true}
              />
              <span>th 32 bytes from operator data, and paste it into the</span>
              <NumberDropdown
                value={data.copyIndex || 0}
                onChange={(value) => onClipboardDataChange(dataIndex, 'copyIndex', value)}
                max={20}
                isTractor={isTractor}
                isReturnDataIndex={false}
              />
              <span>th Farm call, and paste it into the</span>
              <NumberDropdown
                value={data.pasteIndex || 0}
                onChange={(value) => {
                  const finalValue = data.isAdvancedPipe ? parseInt(value) + 1000 : value;
                  onClipboardDataChange(dataIndex, 'pasteIndex', finalValue);
                }}
                max={20}
                isTractor={isTractor}
                isReturnDataIndex={false}
                isAdvancedPipe={data.isAdvancedPipe}
              />
              <span>th input parameter.</span>
              <div className="ml-4 flex items-center flex-wrap">
                <span className="text-sm text-gray-300">Advanced Pipe Function:</span>
                <select
                  value={data.isAdvancedPipe ? parseInt(data.pasteIndex / 1000) : 0}
                  onChange={(e) => {
                    const selectedFunction = parseInt(e.target.value);
                    if (selectedFunction === 0) {
                      // Turning off advanced pipe
                      const newPasteIndex = data.pasteIndex % 1000;
                      onClipboardDataChange(dataIndex, 'isAdvancedPipe', false);
                      onClipboardDataChange(dataIndex, 'pasteIndex', newPasteIndex);
                    } else {
                      // Setting advanced pipe with selected function number
                      const baseIndex = data.pasteIndex % 1000;
                      const newPasteIndex = baseIndex + selectedFunction * 1000;
                      onClipboardDataChange(dataIndex, 'isAdvancedPipe', true);
                      onClipboardDataChange(dataIndex, 'pasteIndex', newPasteIndex);
                    }
                  }}
                  className="ml-2 p-2 rounded-md text-black bg-green-200 text-sm"
                >
                  <option value={0}>Not Advanced Pipe</option>
                  {[...Array(20)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      Function {i + 1}
                    </option>
                  ))}
                </select>
                <span className="ml-2 text-xs text-gray-400">
                  (Selects which function in the Advanced Pipe to paste into)
                </span>
              </div>
              {clipboard.returnPasteParams.length > 1 && (
                <button
                  onClick={() => {
                    onClipboardChange('removeReturnPasteParams', dataIndex);
                  }}
                  className="ml-4 bg-red-500 hover:bg-red-600 p-2 rounded-md text-white text-base"
                >
                  Remove
                </button>
              )}
            </>
          ) : (
            <>
              <span>From the</span>
              <NumberDropdown
                value={data.returnDataIndex || 0}
                onChange={(value) => onClipboardDataChange(dataIndex, 'returnDataIndex', value)}
                max={20}
              />
              <span>call, copy the</span>
              <NumberDropdown
                value={data.copyIndex || 0}
                onChange={(value) => onClipboardDataChange(dataIndex, 'copyIndex', value)}
                max={20}
              />
              <span>return parameter, and paste into the</span>
              <NumberDropdown
                value={data.pasteIndex || 0}
                onChange={(value) => onClipboardDataChange(dataIndex, 'pasteIndex', value)}
                max={20}
              />
              <span>input param.</span>
            </>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="border border-gray-600 p-6 rounded-md bg-gray-800">
      <div className="flex items-center mb-4">
        <h2 className="text-2xl font-bold text-green-400 mr-4">Clipboard Data</h2>
        <select
          value={clipboard.typeId}
          onChange={(e) => {
            onClipboardChange('typeId', e.target.value);
          }}
          className="p-2 rounded-md text-black bg-green-200 text-lg"
        >
          <option value="0x00">No Clipboard</option>
          <option value="0x01">1 Operation</option>
          <option value="0x02">n Operations</option>
        </select>
      </div>

      {showReturnPasteParams()}

      {/* Only show "Add Return Data Param Set" if typeId is 0x02 */}
      {clipboard.typeId === '0x02' && (
        <button
          onClick={() => {
            onClipboardChange('addReturnPasteParams', {
              returnDataIndex: 0,
              copyIndex: 0,
              pasteIndex: 0,
              isAdvancedPipe: false,
            });
          }}
          className="mt-6 bg-green-500 hover:bg-green-600 p-3 rounded-md w-full text-white text-lg"
        >
          Add Clipboard
        </button>
      )}
    </div>
  );
};

export default ClipboardData;
