import React, { useState, useEffect } from 'react';

const FunctionCall = ({ call, availableFunctions, onFunctionChange, onInputChange }) => {
  // State to hold the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Array of function names to include
  const includedFunctions = [
    'mow',
    'mowAll',
    'mowAllMultipleAccounts',
    'mowMultipleAccounts',
    'plant',
    'harvest',
    'gm',
    'sunrise',
    'advancedPipe',
    'transferToken',
    'transferInternalTokenFrom',
    'approveToken',
    'permitToken',
    'getInternalBalance',
    'getInternalBalances',
    'getExternalBalance',
    'getExternalBalances',
    'getBalance',
    'getAllBalance',
    'permitERC20',
    'sowWithMin',
    'harvest',
    'createPodListing',
    'fillPodListing',
    'cancelPodListing',
    'createPodOrder',
    'fillPodOrder',
    'cancelPodOrder',
    'transferPlot',
    'transferPlots',
    'deposit',
    'withdrawDeposit',
    'withdrawDeposits',
    'transferDeposit',
    'transferDeposits',
    'claimAllPlenty',
    'mowMultiple',
    'plant',
    'balanceOf',
    'balanceOfBatch',
    'bdv',
    'getDepositsForAccount',
    'getTokenDepositsForAccount',
    'convert',
    'pipelineConvert',
    'claimAllPlenty',
    'claimPlenty',
    'beanToBdv',
    'unwrapEth',
    'wrapEth',
  ];

  // Filter to include only the specified functions
  const filteredFunctions = availableFunctions.filter((func) =>
    includedFunctions.includes(func.name)
  );

  // Further filter based on the search query to match only the start of the function name
  const searchedFunctions = filteredFunctions.filter((func) =>
    func.name.toLowerCase().startsWith(searchQuery.toLowerCase())
  );

  // Automatically select the closest matching function
  useEffect(() => {
    if (searchedFunctions.length > 0 && call.function !== searchedFunctions[0].name) {
      onFunctionChange(searchedFunctions[0].name);
    }
  }, [searchQuery, searchedFunctions, onFunctionChange, call.function]);

  // Get the selected function's details from the ABI
  const selectedFunction = filteredFunctions.find((func) => func.name === call.function);

  return (
    <div className="mb-4 p-4 border border-gray-600 rounded-md">
      {/* Search input for filtering functions */}
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search function"
        className="p-2 mb-2 rounded-md text-black w-full bg-gray-200"
      />

      <select
        value={call.function}
        onChange={(e) => onFunctionChange(e.target.value)}
        className="p-2 rounded-md text-black w-full bg-gray-200"
      >
        <option value="" disabled>
          Select function
        </option>
        {searchedFunctions.map((func) => (
          <option key={func.name} value={func.name}>
            {func.name}
          </option>
        ))}
      </select>

      {/* Show input fields based on the selected function's inputs */}
      {selectedFunction &&
        selectedFunction.inputs.map((input, index) => (
          <div key={index} className="mt-2">
            <label className="block text-sm text-gray-300 mb-1">
              {input.name ? `${input.name} (${input.type})` : input.type}{' '}
              {/* Show name if available */}
            </label>
            <input
              type="text"
              value={call.inputs[index] || ''}
              placeholder={input.name || input.type}
              onChange={(e) => onInputChange(index, e.target.value)}
              className="p-2 rounded-md text-black w-full bg-green-200"
            />
          </div>
        ))}
    </div>
  );
};

export default FunctionCall;
