import React, { useState, useEffect } from 'react';
import { DIAMOND } from '../Libraries/Constants';
const { ethers, BeanstalkFarm, wellAbi } = require('../FarmEncoder');

const SignatureCreator = ({ signer }) => {
  const [error, setError] = useState('');
  const [signatures, setSignatures] = useState({});
  const [chainId, setChainId] = useState(null);
  const [verifyingContract, setVerifyingContract] = useState(null);
  const [farmCallData, setFarmCallData] = useState('');

  // State for permit parameters
  const [name, setName] = useState('');

  // Add token presets at the top of the component
  const TOKEN_PRESETS = [
    {
      name: 'Pinto',
      address: '0xb170000aeeFa790fa61D6e837d1035906839a3c8',
    },
    {
      name: 'PINTO:WETH Constant Product 2 Upgradeable Well',
      address: '0x3e11001CfbB6dE5737327c59E10afAB47B82B5d3',
    },
    {
      name: 'PINTO:CBETH Constant Product 2 Upgradeable Well',
      address: '0x3e111115A82dF6190e36ADf0d552880663A4dBF1',
    },
    {
      name: 'PINTO:CBBTC Constant Product 2 Upgradeable Well',
      address: '0x3e11226fe3d85142B734ABCe6e58918d5828d1b4',
    },
    {
      name: 'PINTO:USDC Stable 2 Upgradeable Well',
      address: '0x3e1133aC082716DDC3114bbEFEeD8B1731eA9cb1',
    },
    {
      name: 'PINTO:WSOL Constant Product 2 Upgradeable Well',
      address: '0x3e11444c7650234c748D743D8d374fcE2eE5E6C9',
    },
  ];

  // Get chain ID on component mount
  useEffect(() => {
    const getChainId = async () => {
      try {
        if (signer) {
          const network = await (await signer).provider.getNetwork();
          setChainId(network.chainId);
        }
      } catch (err) {
        console.error('Error getting chain ID:', err);
        setError('Failed to get chain ID. Please make sure your wallet is connected.');
      }
    };
    getChainId();
  }, [signer]);

  // Add a function to handle preset selection
  const handlePresetSelect = (preset) => {
    setName(preset.name);
    setVerifyingContract(preset.address);
  };

  const handleSignPermit = async () => {
    if (!signer) {
      setError('Signer not available. Please make sure your wallet is connected.');
      return;
    }

    if (!chainId) {
      setError('Chain ID not available. Please make sure your wallet is connected.');
      return;
    }

    try {
      const resolvedSigner = await signer;
      const address = await resolvedSigner.getAddress();

      // Create domain data
      const domain = {
        name: name,
        version: '1',
        chainId: chainId,
        verifyingContract: verifyingContract,
      };

      // Define the types
      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };

      // Create the data to sign
      const value = {
        owner: address,
        spender: DIAMOND,
        value: ethers.MaxUint256,
        nonce: 0,
        deadline: ethers.MaxUint256,
      };

      // Ethers v6 syntax for signing
      const sig = await resolvedSigner.signTypedData(domain, types, value);
      setSignatures((prev) => ({
        ...prev,
        [verifyingContract]: { signature: sig, name: name },
      }));
      setError('');
    } catch (err) {
      console.error('Error signing permit:', err);
      setError(err.message);
    }
  };

  const handleSignAllNonPinto = async () => {
    if (!signer || !chainId) {
      setError(
        'Signer not available or chain ID not set. Please make sure your wallet is connected.'
      );
      return;
    }

    try {
      const resolvedSigner = await signer;
      const address = await resolvedSigner.getAddress();
      const newSignatures = {};

      // Filter out Pinto token and get all other ERC20s/Wells
      const nonPintoTokens = TOKEN_PRESETS.filter((token) => token.name !== 'Pinto');

      // Create an array of promises for all permit signings
      const signaturePromises = nonPintoTokens.map(async (token) => {
        const domain = {
          name: token.name,
          version: '1',
          chainId: chainId,
          verifyingContract: token.address,
        };

        const types = {
          Permit: [
            { name: 'owner', type: 'address' },
            { name: 'spender', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' },
          ],
        };

        const value = {
          owner: address,
          spender: DIAMOND,
          value: ethers.MaxUint256,
          nonce: 0,
          deadline: ethers.MaxUint256,
        };

        const sig = await resolvedSigner.signTypedData(domain, types, value);
        return { token, signature: sig, owner: address };
      });

      // Wait for all signatures to complete
      const results = await Promise.all(signaturePromises);

      // Process all signatures
      results.forEach(({ token, signature, owner }) => {
        newSignatures[token.address] = { signature, name: token.name, owner };
      });

      setSignatures((prev) => ({
        ...prev,
        ...newSignatures,
      }));

      // Create permit pipe calls for each signature
      const pipeCalls = Object.entries(newSignatures).map(([address, { signature, owner }]) => {
        const sig = ethers.Signature.from(signature);
        return {
          target: address,
          function: 'permit',
          inputs: [
            owner, // owner
            DIAMOND, // spender
            ethers.MaxUint256, // value
            ethers.MaxUint256, // deadline
            Number(sig.v), // convert v to number
            sig.r,
            sig.s,
          ],
          clipboard: {
            typeId: '0x00',
            returnPasteParams: [],
          },
        };
      });

      // Create the farm call that contains the advanced pipe
      const beanstalkFarm = new BeanstalkFarm([wellAbi]);
      try {
        // First create the pipe call struct
        const pipeCallStruct = beanstalkFarm.createAdvPipeCallStruct(pipeCalls);

        // Then create the farm call with the pipe call struct
        const farmCallData = beanstalkFarm.generateAdvancedFarmCall([
          {
            function: 'advancedPipe',
            inputs: [pipeCallStruct, 0],
            clipboard: {
              typeId: '0x00',
              returnPasteParams: [],
            },
          },
        ]);
        setFarmCallData(farmCallData);
        setError('');
      } catch (err) {
        console.error('Error generating farm call:', err);
        setError('Error generating farm call: ' + err.message);
      }
    } catch (err) {
      console.error('Error signing permits:', err);
      setError(err.message);
    }
  };

  return (
    <div className="bg-gray-800 p-4 rounded-md">
      <h3 className="text-xl font-semibold text-green-400 mb-4">Sign ERC20 Permit</h3>

      <p className="text-sm italic text-white mb-4">
        Signs a message to infinitely approve pinto for the specified ERC20.
      </p>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {TOKEN_PRESETS.map((preset) => (
          <button
            key={preset.address}
            onClick={() => handlePresetSelect(preset)}
            className={`${
              signatures[preset.address] ? 'bg-green-500' : 'bg-blue-500'
            } hover:bg-blue-600 text-white p-2 rounded-md text-sm`}
          >
            {preset.name.split(' ')[0]}
            {signatures[preset.address] && ' ✓'}
          </button>
        ))}
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block text-white mb-2">
          Token Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full bg-gray-700 text-white p-2 rounded-md"
          placeholder="Enter name"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="verifyingContract" className="block text-white mb-2">
          Verifying Contract:
        </label>
        <input
          type="text"
          id="verifyingContract"
          value={verifyingContract}
          onChange={(e) => setVerifyingContract(e.target.value)}
          className="w-full bg-gray-700 text-white p-2 rounded-md"
          placeholder="Enter verifying contract address"
        />
      </div>

      <div className="flex gap-4 mb-4">
        <button
          onClick={handleSignPermit}
          className="bg-green-500 hover:bg-green-600 text-white p-2 rounded-md flex-1"
        >
          Sign Single ERC20 Permit
        </button>

        <button
          onClick={handleSignAllNonPinto}
          className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md flex-1"
        >
          Sign All Non-Pinto Permits
        </button>
      </div>

      {Object.keys(signatures).length > 0 && (
        <div className="mt-4">
          <h4 className="text-white mb-2">Generated Signatures:</h4>
          {Object.entries(signatures).map(([address, { signature, name }]) => (
            <div key={address} className="mb-4">
              <p className="text-white text-sm">{name}:</p>
              <div className="bg-gray-700 p-2 rounded-md break-all text-white text-sm">
                {signature}
              </div>
            </div>
          ))}
        </div>
      )}

      {farmCallData && (
        <div className="mt-4 border-2 border-gray-600 p-4 rounded-lg">
          <label htmlFor="farmCallData" className="block text-sm font-semibold text-white mb-2">
            Farm Call Data (click to copy):
          </label>
          <textarea
            id="farmCallData"
            value={farmCallData}
            readOnly
            onClick={(e) => {
              e.target.select();
              navigator.clipboard.writeText(farmCallData);
            }}
            className="w-full p-2 bg-gray-700 text-white rounded cursor-pointer hover:bg-gray-600"
            rows="4"
            placeholder="Generated farm call data will appear here"
          />
        </div>
      )}

      {error && <div className="mt-4 text-red-500">Error: {error}</div>}
    </div>
  );
};

export default SignatureCreator;
