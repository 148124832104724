// src/components/AbiInput.js
import React, { useState } from 'react';

const AbiInput = ({ onAddAbi }) => {
  const [currentUserAbi, setCurrentUserAbi] = useState('');

  const handleAddAbi = () => {
    try {
      const abi = JSON.parse(currentUserAbi);
      onAddAbi(abi);
      setCurrentUserAbi('');
    } catch (error) {
      alert('Invalid ABI format. Please enter a valid JSON ABI.');
    }
  };

  return (
    <div className="mb-6 bg-73956F p-4 rounded-md shadow-lg">
      <h2 className="text-lg font-bold text-FCEFF9">Custom ABI</h2>
      <textarea
        value={currentUserAbi}
        onChange={(e) => setCurrentUserAbi(e.target.value)}
        placeholder="Paste ABI JSON here"
        className="w-full p-2 rounded-md text-black mt-2 bg-green-200"
        rows="4"
      ></textarea>
      <button
        onClick={handleAddAbi}
        className="mt-2 bg-95D7AE hover:bg-7BAE7F p-2 rounded-md w-full text-black"
      >
        Add ABI
      </button>
    </div>
  );
};

export default AbiInput;
