export const getFunctionNamesFromAbi = (abi) => {
  if (!Array.isArray(abi)) {
    console.error('Invalid ABI format:', abi);
    return []; // Return an empty array if the ABI is invalid
  }

  return abi
    .filter((item) => item.type === 'function')
    .map((item) => ({
      name: item.name,
      inputs: item.inputs || [],
      stateMutability: item.stateMutability,
    }));
};
