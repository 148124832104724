const DIAMOND = '0xD1A0D188E861ed9d15773a2F3574a2e94134bA8f';
const GARDEN = '0xBadd1e0A7E7F8f6e5302f17b71b43Ead630E7A13';
const NORMIE_GARDEN = '0xF31c260Df287106B3F86e72D8935943Ba95A36b9';
const PINTO = '0xb170000aeeFa790fa61D6e837d1035906839a3c8';
const PINTO_ETH = '0x3e11001CfbB6dE5737327c59E10afAB47B82B5d3';
const PINTO_CBETH = '0x3e111115A82dF6190e36ADf0d552880663A4dBF1';
const PINTO_CBBTC = '0x3e11226fe3d85142B734ABCe6e58918d5828d1b4';
const PINTO_USDC = '0x3e1133aC082716DDC3114bbEFEeD8B1731eA9cb1';
const PINTO_WSOL = '0x3e11444c7650234c748D743D8d374fcE2eE5E6C9';
export {
  DIAMOND,
  GARDEN,
  NORMIE_GARDEN,
  PINTO,
  PINTO_ETH,
  PINTO_CBETH,
  PINTO_CBBTC,
  PINTO_USDC,
  PINTO_WSOL,
};
