// Import the core modules and classes
const BeanstalkFarm = require('./Classes/BeanstalkFarm');
const AdvancedFarm = require('./Classes/AdvancedFarm');
const AdvancedPipe = require('./Classes/AdvancedPipe');
const Helpers = require('./Libraries/Helpers');
const { ethers } = require('ethers');
const wellAbi = require('./Abi/well.json');
const gardenAbi = require('./Abi/Garden.json');
const beanstalkAbi = require('./Abi/beanstalk.json');

// Export all in one object
module.exports = {
  BeanstalkFarm,
  AdvancedFarm,
  AdvancedPipe,
  Helpers,
  ethers,
  wellAbi,
  gardenAbi,
  beanstalkAbi,
};
