import { configureChains, createConfig } from '@wagmi/core';
import { arbitrum } from '@wagmi/core/chains';
import { publicProvider } from '@wagmi/core/providers/public';
import { createModal } from '@rabby-wallet/rabbykit';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum],
  [publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
});

export const rabbyKit = createModal({
  chains,
  wagmi: config,
  projectId: '3f34b4cbb960fe10983c67e19e5d33c2', // Make sure this is replaced
  appName: 'tractorTester',
});
