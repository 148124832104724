// Tractor.js
import React, { useState, useEffect } from 'react';
import { getRequisition, encodeOperatorPasteInstr } from '../Libraries/Helpers';
import ClipboardData from './ClipboardData';
import { ethers } from 'ethers';
import beanstalkABI from '../Abi/beanstalk.json';

const Tractor = ({ advancedFarmCalldata, signer }) => {
  const [resolvedSigner, setResolvedSigner] = useState(null);
  const [requisition, setRequisition] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [operatorPasteInstr, setOperatorPasteInstr] = useState({
    typeId: '0x00',
    returnPasteParams: [{ returnDataIndex: '0', copyIndex: '0', pasteIndex: '0' }],
  });
  const [maxNonce, setMaxNonce] = useState('');
  const [startTime, setStartTime] = useState('0');
  const [endTime, setEndTime] = useState(ethers.MaxUint256.toString());
  const [userInputBytecode, setUserInputBytecode] = useState('');
  const [description, setDescription] = useState('Requisition');
  const [rawOperatorInstructions, setRawOperatorInstructions] = useState('');
  const [bulkPasteError, setBulkPasteError] = useState('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishError, setPublishError] = useState('');
  const [exportFilename, setExportFilename] = useState('requisition');

  useEffect(() => {
    const resolveSigner = async () => {
      if (signer && typeof signer.then === 'function') {
        // If signer is a promise, resolve it
        const resolved = await signer;
        setResolvedSigner(resolved);
      } else {
        // If signer is already resolved, use it directly
        setResolvedSigner(signer);
      }
    };

    resolveSigner();
  }, [signer]);

  const handleCreateTractorSignature = async () => {
    const callDataToUse = userInputBytecode || advancedFarmCalldata;

    if (!callDataToUse || !resolvedSigner) {
      console.error('Missing call data or signer!');
      setError('Missing call data or signer!');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      let operatorInstructions;
      // If we have raw instructions, use them directly
      if (rawOperatorInstructions) {
        operatorInstructions = rawOperatorInstructions;
      } else {
        // Otherwise use the UI state
        operatorInstructions = encodeOperatorPasteInstr(operatorPasteInstr);
      }

      const requisitionResult = await getRequisition(
        resolvedSigner,
        callDataToUse,
        operatorInstructions,
        maxNonce,
        startTime,
        endTime
      );
      setRequisition(requisitionResult);
    } catch (err) {
      setError('Failed to create tractor signature');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClipboardChange = (field, value) => {
    setOperatorPasteInstr((prevState) => {
      if (field === 'addReturnPasteParams') {
        return {
          ...prevState,
          returnPasteParams: [
            ...prevState.returnPasteParams,
            { returnDataIndex: '0', copyIndex: '0', pasteIndex: '0' },
          ],
        };
      } else if (field === 'removeReturnPasteParams') {
        return {
          ...prevState,
          returnPasteParams: prevState.returnPasteParams.filter((_, i) => i !== value),
        };
      } else if (field === 'typeId') {
        // When changing typeId, ensure proper initialization of returnPasteParams
        let updatedParams = [...prevState.returnPasteParams];
        if (value === '0x01' && updatedParams.length > 1) {
          // For type 0x01, keep only the first entry
          updatedParams = [updatedParams[0]];
        } else if (value === '0x02' && updatedParams.length === 0) {
          // For type 0x02, ensure at least one entry exists
          updatedParams = [{ returnDataIndex: '0', copyIndex: '0', pasteIndex: '0' }];
        }
        return {
          ...prevState,
          typeId: value,
          returnPasteParams: updatedParams,
        };
      } else {
        return {
          ...prevState,
          [field]: value,
        };
      }
    });
  };

  const handleClipboardDataChange = (paramIndex, field, value) => {
    setOperatorPasteInstr((prevState) => {
      const updatedParams = [...prevState.returnPasteParams];
      if (!updatedParams[paramIndex]) {
        updatedParams[paramIndex] = { returnDataIndex: '0', copyIndex: '0', pasteIndex: '0' };
      }
      updatedParams[paramIndex] = {
        ...updatedParams[paramIndex],
        [field]: value,
      };
      return {
        ...prevState,
        returnPasteParams: updatedParams,
      };
    });
  };

  const exportRequisitionAsJson = () => {
    if (requisition) {
      const requisitionData = {
        publisher: requisition.publisher,
        data: requisition.advancedFarmCalldata,
        operatorPasteInstr: requisition.operatorPasteInstr,
        maxNonce: requisition.maxNonce,
        startTime: requisition.startTime,
        endTime: requisition.endTime,
        blueprintHash: requisition.blueprintHash,
        signature: requisition.signature,
        description: description,
      };

      const jsonString = JSON.stringify(requisitionData, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      // Add .json extension if not present
      const finalFilename = exportFilename.endsWith('.json')
        ? exportFilename
        : `${exportFilename}.json`;

      const a = document.createElement('a');
      a.href = url;
      a.download = finalFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const handleOperatorInstrBulkPaste = (rawInput) => {
    try {
      // Clear previous error
      setBulkPasteError('');

      // If input is empty, clear raw instructions and return
      if (!rawInput.trim()) {
        setRawOperatorInstructions('');
        return;
      }

      // Parse the input as JSON if it's a JSON string
      let instructions = rawInput;
      try {
        instructions = JSON.parse(rawInput);
      } catch (err) {
        throw new Error('Invalid JSON format');
      }

      // Validate that we have an array of hex strings
      if (!Array.isArray(instructions)) {
        throw new Error('Input must be an array of hex strings');
      }

      // Store the raw instructions for direct use in signature creation
      setRawOperatorInstructions(instructions);
    } catch (err) {
      setBulkPasteError(err.message);
      console.error(err);
    }
  };

  const handlePublishRequisition = async () => {
    if (!requisition || !resolvedSigner) return;

    setIsPublishing(true);
    setPublishError('');

    try {
      const beanstalk = new ethers.Contract(
        '0xD1A0D188E861ed9d15773a2F3574a2e94134bA8f',
        beanstalkABI,
        resolvedSigner
      );

      // Create the Blueprint struct
      const blueprint = {
        publisher: requisition.publisher,
        data: requisition.advancedFarmCalldata,
        operatorPasteInstrs: requisition.operatorPasteInstr,
        maxNonce: requisition.maxNonce,
        startTime: requisition.startTime,
        endTime: requisition.endTime,
      };

      // Create the Requisition struct
      const requisitionStruct = {
        blueprint: blueprint,
        blueprintHash: requisition.blueprintHash,
        signature: requisition.signature,
      };

      const tx = await beanstalk.publishRequisition(requisitionStruct);
      await tx.wait();
    } catch (err) {
      setPublishError('Failed to publish requisition: ' + err.message);
      console.error(err);
    } finally {
      setIsPublishing(false);
    }
  };

  const handleImportJson = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        console.log(json);

        // Update all the relevant state
        setUserInputBytecode(json.data || '');
        const operatorPasteInstrs = json.operatorPasteInstr || json.operatorPasteInstrs;
        if (operatorPasteInstrs) {
          setRawOperatorInstructions(operatorPasteInstrs);
          // Update the textarea with the formatted JSON string
          const textareaElement = document.getElementById('bulkOperatorInstr');
          if (textareaElement) {
            textareaElement.value = JSON.stringify(operatorPasteInstrs, null, 2);
          }
        }
        setMaxNonce(json.maxNonce || '');
        setStartTime(json.startTime || '0');
        setEndTime(json.endTime || ethers.MaxUint256.toString());
        setDescription(json.description || 'Requisition');

        // If we have a complete requisition, set it
        if (json.blueprintHash && json.signature) {
          setRequisition({
            publisher: json.publisher,
            advancedFarmCalldata: json.data,
            operatorPasteInstr: operatorPasteInstrs,
            maxNonce: json.maxNonce,
            startTime: json.startTime,
            endTime: json.endTime,
            blueprintHash: json.blueprintHash,
            signature: json.signature,
          });
        }
      } catch (err) {
        setError('Failed to parse JSON file: ' + err.message);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div className="tractor-component bg-gray-800 p-4 rounded-md mb-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-green-400">Tractor</h2>
        <div className="relative">
          <input
            type="file"
            accept=".json"
            onChange={handleImportJson}
            className="hidden"
            id="jsonInput"
          />
          <label
            htmlFor="jsonInput"
            className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded cursor-pointer"
          >
            Import JSON
          </label>
        </div>
      </div>

      <h2 className="text-sm italic text-white mb-2">
        Tractor allows for dynamic calldata set by operator inputted data. This is indexed as:
        (index to copy data from), (FarmCall index to paste into), and (param index to paste into).
        (0, 1, 0) means to copy the first 32 bytes from operator data, and paste it into the first
        parameter of the 2nd Farm Call.
      </h2>
      <h2 className="text-sm italic text-white mb-2">
        Copy and paste params are restricted to 32 byte sizes, and can only be copied from and
        pasted into 32 byte increments. User should be aware of how arrays are encoded to properly
        paste data.
      </h2>

      <div className="mb-4 border-2 border-gray-600 p-4 rounded-lg">
        <label htmlFor="userInputBytecode" className="block text-sm font-semibold text-white mb-2">
          Farm Call Bytecode (optional)
        </label>
        <textarea
          id="userInputBytecode"
          value={userInputBytecode}
          onChange={(e) => setUserInputBytecode(e.target.value)}
          placeholder="Enter farm call bytecode (leave empty to use encoded call data)"
          className="w-full p-2 bg-gray-700 text-white rounded"
          rows="4"
        />
      </div>

      <div className="mb-4 border-2 border-gray-600 p-4 rounded-lg">
        <label htmlFor="bulkOperatorInstr" className="block text-sm font-semibold text-white mb-2">
          Bulk Paste Operator Instructions
        </label>
        <textarea
          id="bulkOperatorInstr"
          value={rawOperatorInstructions ? JSON.stringify(rawOperatorInstructions, null, 2) : ''}
          onChange={(e) => handleOperatorInstrBulkPaste(e.target.value)}
          placeholder="Paste operator instructions array here (e.g. ['0x...', '0x...', '0x...'])"
          className="w-full p-2 bg-gray-700 text-white rounded"
          rows="4"
        />
        {bulkPasteError && <p className="text-red-500 text-sm mt-2">Error: {bulkPasteError}</p>}
      </div>

      {(!rawOperatorInstructions || rawOperatorInstructions.length === 0) && (
        <div className="mb-8">
          <ClipboardData
            clipboard={operatorPasteInstr}
            onClipboardChange={handleClipboardChange}
            onClipboardDataChange={handleClipboardDataChange}
            isTractor={true}
          />
        </div>
      )}

      <div className="flex gap-2 mb-2">
        <div className="flex flex-1">
          <input
            type="text"
            value={maxNonce}
            onChange={(e) => setMaxNonce(e.target.value)}
            placeholder="Max Nonce"
            className="flex-grow p-2 bg-gray-700 text-white rounded-l"
          />
          <button
            onClick={() => setMaxNonce(ethers.MaxUint256.toString())}
            className="bg-blue-500 hover:bg-blue-600 p-2 rounded-r text-white"
          >
            Max
          </button>
        </div>

        <input
          type="text"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          placeholder="Start Time (uint256)"
          className="flex-1 p-2 bg-gray-700 text-white rounded"
        />

        <input
          type="text"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          placeholder="End Time (uint256)"
          className="flex-1 p-2 bg-gray-700 text-white rounded"
        />
      </div>

      <div className="mb-2">
        <label htmlFor="description" className="block text-sm font-medium text-white mb-2"></label>
        <input
          type="text"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          className="w-full p-2 bg-gray-700 text-white rounded"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="encodedCallData"
          className="block text-sm font-medium text-white mb-2"
        ></label>
        <textarea
          id="encodedCallData"
          value={advancedFarmCalldata}
          readOnly
          placeholder="Encoded calldata"
          className="w-full p-2 bg-gray-700 text-white rounded"
          rows="4"
        />
      </div>

      <div className="flex gap-4 w-full">
        <button
          onClick={handleCreateTractorSignature}
          className="bg-blue-500 hover:bg-blue-600 p-2 rounded-md text-white flex-1"
          disabled={isLoading || !resolvedSigner}
        >
          {isLoading ? 'Processing...' : 'Create Tractor Signature'}
        </button>

        <button
          onClick={handlePublishRequisition}
          className="bg-green-500 hover:bg-green-600 p-2 rounded-md text-white flex-1"
          disabled={isPublishing || !requisition}
        >
          {isPublishing ? 'Publishing...' : 'Publish Requisition'}
        </button>
      </div>

      {publishError && <p className="text-red-500 mt-2">{publishError}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}

      {requisition && (
        <div className="mt-4 bg-gray-700 p-4 rounded-md">
          <h3 className="text-lg font-semibold text-green-400">Requisition Details:</h3>
          <div className="overflow-x-auto">
            <p className="mt-2">
              <strong>Publisher:</strong> <span className="break-all">{requisition.publisher}</span>
              <br />
              <strong>Data:</strong>{' '}
              <span className="break-all">{requisition.advancedFarmCalldata}</span>
              <br />
              <strong>Operator Paste Instructions:</strong>
              <div className="break-all">
                {requisition.operatorPasteInstr.map((instr, index) => (
                  <div key={index} className="mt-2">
                    <span>{instr}</span>
                  </div>
                ))}
              </div>
              <br />
              <strong>Max Nonce:</strong> <span className="break-all">{requisition.maxNonce}</span>
              <br />
              <strong>Start Time:</strong>{' '}
              <span className="break-all">{requisition.startTime}</span>
              <br />
              <strong>End Time:</strong> <span className="break-all">{requisition.endTime}</span>
            </p>
            <p className="mt-2">
              <strong>Hash:</strong> <span className="break-all">{requisition.blueprintHash}</span>
            </p>
            <p className="mt-2">
              <strong>Signature:</strong> <span className="break-all">{requisition.signature}</span>
            </p>
          </div>
          <div className="mt-4 flex gap-2 items-center">
            <input
              type="text"
              value={exportFilename}
              onChange={(e) => setExportFilename(e.target.value)}
              placeholder="Enter filename"
              className="flex-1 p-2 bg-gray-600 text-white rounded"
            />
            <button
              onClick={exportRequisitionAsJson}
              className="bg-blue-500 hover:bg-blue-600 p-2 rounded-md text-white whitespace-nowrap"
            >
              Export Requisition as JSON
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tractor;
