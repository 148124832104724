const { ethers } = require("ethers");
const { DIAMOND } = require("./Constants");

class Helpers {
  /**
   * @dev Represents the Clipboard used in both AdvancedPipe and AdvancedFarm
   * @param {number} typeId - Indicates the type of clipboard (0x01 or 0x02)
   * @param {number} etherValue - Amount of Ether being transferred
   * @param {object[]} returnPasteParams - Array of paste parameters
   */
  static Clipboard = class {
    constructor(typeId, etherValue, returnPasteParams) {
      this.typeId = typeId;
      this.etherValue = etherValue;
      this.returnPasteParams = returnPasteParams;
    }
  };

  static encodeCallDataAndClipboard(_interface, _functions, _inputs, _clipboard) {
    console.log("in encodeCallDataAndClipboard");
    console.log("_clipboard", _clipboard);
    console.log("_functions", _functions);
    console.log("_inputs", _inputs);
    const callData = Helpers.generateCallData(_interface, _functions, _inputs);
    console.log("callData", callData);
    const clipboard = Helpers.encodeClipboard(_clipboard);
    console.log("clipboard", clipboard);
    return { callData, clipboard };
  }
  /**
   * @notice Encodes clipboard data into the correct format for the contract.
   */
  static encodeClipboard(clipboard) {
    const typeId = clipboard.typeId;
    const useEther = clipboard.etherValue > 0 ? "0x01" : "0x00";
    const etherValue = clipboard.etherValue;

    let returnPasteParams;
    if (typeId !== "0x00") {
      returnPasteParams = clipboard.returnPasteParams.map((pp) =>
        Helpers.convertReturnPasteParams(JSON.parse(JSON.stringify(pp)))
      );
    }

    let encodedData;
    if (typeId === "0x00") {
      encodedData = ethers.solidityPacked(["uint8", "bytes1"], [typeId, useEther]);
    } else if (typeId === "0x01") {
      encodedData = ethers.solidityPacked(
        ["uint8", "bytes1", "uint80", "uint80", "uint80"],
        [
          typeId,
          useEther,
          returnPasteParams[0].returnDataIndex,
          returnPasteParams[0].copyIndex,
          returnPasteParams[0].pasteIndex,
        ]
      );
    } else if (typeId === "0x02") {
      const returnPasteParamsArray = [];
      for (const pp of returnPasteParams) {
        returnPasteParamsArray.push(Helpers.encodeType2PasteParams(pp, "0x0200"));
      }
      // First pack typeId and useEther into a bytes32
      const header = ethers.solidityPacked(["uint8", "bytes1"], [typeId, useEther]);

      // Then use ABI encode to combine with the bytes32 array (ethers v6 syntax)
      encodedData = new ethers.AbiCoder().encode(
        ["bytes2", "bytes32[]"],
        [header, returnPasteParamsArray]
      );
      console.log("encodedData", encodedData);
    }

    if (useEther === "0x01" && typeId !== "0x00") {
      encodedData = ethers.solidityPacked(["bytes", "uint256"], [encodedData, etherValue]);
    }

    return encodedData;
  }

  /**
   * @notice Encodes the operator paste instructions.
   */
  static encodeOperatorPasteInstr(operatorPasteInstr) {
    const typeId = operatorPasteInstr.typeId;
    // return an empty array.
    if (typeId === "0x00") {
      return [];
    }

    // return a single element array with the operator paste instructions.
    if (typeId === "0x01") {
      const rpp = Helpers.convertOperatorPasteInstrs(
        JSON.parse(JSON.stringify(operatorPasteInstr.returnPasteParams[0]))
      );
      return [Helpers.encodeType2PasteParams(rpp, "0x0000")];
    }

    // return an n array with the operator paste instructions.
    if (typeId === "0x02") {
      const data = operatorPasteInstr.returnPasteParams.map((pp) => {
        const rpp = Helpers.convertOperatorPasteInstrs(JSON.parse(JSON.stringify(pp)));
        return Helpers.encodeType2PasteParams(rpp, "0x0000");
      });
      return data;
    }
  }

  /**
   * @notice Encodes the paste parameters for type 2.
   */
  static encodeType2PasteParams(pp, header) {
    return ethers.solidityPacked(
      ["bytes2", "uint80", "uint80", "uint80"],
      [header, pp.returnDataIndex, pp.copyIndex, pp.pasteIndex]
    );
  }

  /**
   * @notice Converts return paste params for compatibility with encoding.
   * @dev If returnPasteParam, the return is (copyReturnIndex, copyByteIndex, pasteByteIndex).
   */
  static convertReturnPasteParams(returnPasteParams) {
    returnPasteParams.copyIndex = 32 + returnPasteParams.copyIndex * 32;
    returnPasteParams.pasteIndex = 4 + 32 + returnPasteParams.pasteIndex * 32;
    return returnPasteParams;
  }

  /**
   * @notice Converts operator paste instructions for compatibility with encoding.
   * @dev If operatorPasteInstr, the return is (bytes2, copyByteIndex, pasteCallIndex, pasteByteIndex).
   */
  static convertOperatorPasteInstrs(operatorPasteInstrs) {
    const UINT80_MAX = "0xffffffffffffffffffff"; // 20 f's for uint80 max
    const UINT80_MAX_MINUS_ONE = "0xfffffffffffffffffffe"; // max - 1
    if (
      operatorPasteInstrs.returnDataIndex !== UINT80_MAX &&
      operatorPasteInstrs.returnDataIndex !== UINT80_MAX_MINUS_ONE
    ) {
      operatorPasteInstrs.returnDataIndex = 32 + operatorPasteInstrs.returnDataIndex * 32;
    }

    // Check if pasteIndex >= 1000 (indicating it's for an advancedPipeCall)
    const originalPasteIndex = parseInt(operatorPasteInstrs.pasteIndex);
    if (originalPasteIndex >= 1000) {
      // Find out what multiple of 1000 it is
      const n = Math.floor(originalPasteIndex / 1000);
      // Get the actual paste index by removing n * 1000 from the original
      const actualPasteIndex = originalPasteIndex - n * 1000;
      // Use the new formula
      operatorPasteInstrs.pasteIndex = 4 + 32 + actualPasteIndex * 32 + 4 + 32 * (n + 7);
    } else {
      // Use the original formula for normal cases
      operatorPasteInstrs.pasteIndex = 4 + 32 + originalPasteIndex * 32;
    }

    return operatorPasteInstrs;
  }

  /**
   * @notice Generates the `callData` for any function.
   * @param {object} contractInterface - The contract interface from ethers.js
   * @param {string} functionName - The name of the function to encode
   * @param {array} inputs - The inputs for the function
   * @returns {string} The encoded callData
   */
  static generateCallData(contractInterface, functionName, inputs) {
    console.log("in generateCallData");
    const processedInputs = inputs.map((input) => {
      console.log("input", input);
      if (typeof input === "object" && input !== null && "value" in input) {
        // If the input is an object with a 'value' property, use that value
        return input.value;
      } else if (typeof input === "string" && input.startsWith("[") && input.endsWith("]")) {
        // Parse stringified arrays
        console.log("input is a stringified array");
        return input
          .slice(1, -1)
          .split(",")
          .map((item) => item.trim());
      }
      return input;
    });
    console.log("processedInputs", processedInputs);
    // Find all function fragments that match the given name
    const matchingFragments = contractInterface.fragments.filter(
      (fragment) => fragment.name === functionName
    );

    if (matchingFragments.length > 1) {
      // If there are multiple matching fragments, we need to find the correct one
      const correctFragment = matchingFragments.find((fragment) => {
        // Check if the number of inputs matches
        console.log("fragment.inputs.length", fragment.inputs.length);
        console.log("processedInputs.length", processedInputs.length);
        if (fragment.inputs.length !== processedInputs.length) return false;

        // Check if the types of inputs match
        return fragment.inputs.every((input, index) => {
          const processedInput = processedInputs[index];
          if (Array.isArray(processedInput)) {
            return input.type.startsWith("tuple") || input.type.endsWith("[]");
          }
          // For simplicity, we're not doing extensive type checking here
          // You might want to add more sophisticated type checking if needed
          return true;
        });
      });

      if (!correctFragment) {
        throw new Error(`No matching function found for ${functionName} with the given inputs`);
      }

      // Use the correct fragment to encode the function data
      console.log("correctFragment", correctFragment);
      console.log("processedInputs", processedInputs);
      return contractInterface.encodeFunctionData(correctFragment, processedInputs);
    } else {
      // If there's only one matching fragment, we can use the original method
      console.log("functionName", functionName);
      console.log("processedInputs", processedInputs);
      return contractInterface.encodeFunctionData(functionName, processedInputs);
    }
  }

  static combineAbis(abis) {
    const combinedAbi = [];
    for (const abi of abis) {
      combinedAbi.push(...abi);
    }
    return combinedAbi;
  }

  /**
   * @notice Helper function for mixins (combines multiple classes).
   * @param {Class} BaseClass - The base class to extend
   * @param  {...Class} Mixins - Classes to mix into the base class
   */
  static mix(BaseClass, ...Mixins) {
    return Mixins.reduce(
      (Base, Mixin) =>
        class extends Base {
          constructor(...args) {
            super(...args);
            Object.assign(this, new Mixin(...args));
          }
        },
      BaseClass
    );
  }

  static async getRequisition(
    signer,
    advancedFarmCalldata,
    operatorPasteInstr,
    maxNonce,
    startTime,
    endTime
  ) {
    const publisher = await signer.getAddress();
    const network = await signer.provider.getNetwork();
    const chainId = network.chainId;
    console.log("advancedFarmCalldata", advancedFarmCalldata);

    const blueprint = {
      publisher: publisher,
      data: advancedFarmCalldata,
      operatorPasteInstrs: operatorPasteInstr,
      maxNonce: maxNonce,
      startTime: startTime,
      endTime: endTime,
    };
    const domain = {
      name: "Tractor",
      version: "1.0.0",
      chainId: 8453, // change to 8453 on mainnet.
      verifyingContract: DIAMOND,
    };

    // Define the Blueprint type structure
    const types = {
      Blueprint: [
        { name: "publisher", type: "address" },
        { name: "data", type: "bytes" },
        { name: "operatorPasteInstrs", type: "bytes32[]" },
        { name: "maxNonce", type: "uint256" },
        { name: "startTime", type: "uint256" },
        { name: "endTime", type: "uint256" },
      ],
    };

    const blueprintHash = ethers.TypedDataEncoder.hash(domain, types, blueprint);
    const signature = await Helpers.signRequisition(blueprint, signer, chainId);
    return {
      publisher,
      advancedFarmCalldata,
      operatorPasteInstr,
      maxNonce,
      startTime,
      endTime,
      blueprintHash,
      signature,
    };
  }

  static async signRequisition(blueprint, signer, chainId) {
    const domain = {
      name: "Tractor",
      version: "1.0.0",
      chainId: chainId,
      verifyingContract: DIAMOND,
    };
    const types = {
      Blueprint: [
        { name: "publisher", type: "address" },
        { name: "data", type: "bytes" },
        { name: "operatorPasteInstrs", type: "bytes32[]" },
        { name: "maxNonce", type: "uint256" },
        { name: "startTime", type: "uint256" },
        { name: "endTime", type: "uint256" },
      ],
    };
    const value = blueprint;
    const signature = await signer.signTypedData(domain, types, value);
    return signature;
  }

  static async signPermit(owner, name, verifyingContract, signer, chainId) {
    const domain = {
      name: name, // Replace with the token's name
      version: "1", // Replace with the token's version
      chainId: chainId,
      verifyingContract: verifyingContract,
    };
    const types = {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };
    const message = {
      owner: (await signer).address,
      spender: 0xd1a0d188e861ed9d15773a2f3574a2e94134ba8f,
      value: "1000000000000000000",
      nonce: 0,
      deadline: "115792089237316195423570985008687907853269984665640564039457584007913129639935",
    };
    const signature = await signer.signTypedData(domain, types, message);
    return signature;
  }

  static async calculateBlueprintHash(blueprint) {
    // EIP-712 domain separator parameters
    const domain = {
      name: "Tractor",
      version: "1.0.0",
      chainId: 8453, // change to 8453 on mainnet.
      verifyingContract: DIAMOND,
    };

    // Define the Blueprint type structure
    const types = {
      Blueprint: [
        { name: "publisher", type: "address" },
        { name: "data", type: "bytes" },
        { name: "operatorPasteInstrs", type: "bytes32[]" },
        { name: "maxNonce", type: "uint256" },
        { name: "startTime", type: "uint256" },
        { name: "endTime", type: "uint256" },
      ],
    };

    // let boobieBaddie = "0x000000000000000000000000000000000000000000000000000badd1eb00b135";
    // const { success, index } = this.findBytes(blueprint.data, boobieBaddie);

    // if (!success) {
    //   throw new Error("Incorrect data encoding, must have baddie bytes");
    // }

    // while (true) {
    //   // Create a copy of the blueprint with current boobieBaddie
    //   const currentBlueprint = {
    //     ...blueprint,
    //     data: this.replaceBytes(blueprint.data, boobieBaddie, index),
    //   };

    //   // Calculate the hash using ethers v6 TypedDataEncoder
    const currentBlueprint = {
      ...blueprint,
      data: blueprint.data,
    };
    const blueprintHash = ethers.TypedDataEncoder.hash(domain, types, currentBlueprint);
    //   // check if the hash has the baddie bytes.
    //   if (blueprintHash.slice(0, 8) === "0xbadd1e") {
    //     // return the blueprint and hash.
    //     return { blueprint: currentBlueprint, hash: blueprintHash };
    //   }

    //   boobieBaddie = this.incrementBaddieBytes(boobieBaddie);
    // }

    return { blueprint: currentBlueprint, hash: blueprintHash };
  }

  /**
   * @notice Searches for a 32-byte sequence where the last 7 bytes are 0xbadd1eb00b1e5
   * @param {string} haystack - The byte string to search in (hex string starting with 0x)
   * @param {string} needle - The 32-byte sequence to search for (hex string starting with 0x)
   * @returns {Object} Object containing success boolean and index number (-1 if not found)
   */
  static findBytes(haystack, needle) {
    // Remove '0x' prefix if present and convert to lowercase
    const haystackClean = haystack.toLowerCase().startsWith("0x")
      ? haystack.slice(2).toLowerCase()
      : haystack.toLowerCase();
    const needleClean = needle.toLowerCase().startsWith("0x")
      ? needle.slice(2).toLowerCase()
      : needle.toLowerCase();

    // Validate input lengths
    if (needleClean.length !== 64) {
      // 32 bytes = 64 hex characters
      throw new Error("Needle must be exactly 32 bytes long");
    }

    // Check if the last 7 bytes match 0xbadd1eb00b135
    const expectedSuffix = "badd1eb00b135";
    if (!needleClean.endsWith(expectedSuffix)) {
      throw new Error("Needle must end with 0xbadd1eb00b135");
    }

    // Validate haystack length
    if (haystackClean.length < 64) {
      // Must be at least 32 bytes
      return { success: false, index: -1 };
    }

    // Search for the needle in the haystack
    const index = haystackClean.indexOf(needleClean);

    return {
      success: index !== -1,
      index: index !== -1 ? index / 2 : -1, // Divide by 2 to get byte index (since hex uses 2 chars per byte)
    };
  }

  /**
   * @notice Replaces 32 bytes in the target bytes at the specified index with source bytes
   * @param {string} target - The byte string to modify (hex string starting with 0x)
   * @param {string} source - The 32-byte sequence to insert (hex string starting with 0x)
   * @param {number} index - The byte index where to start the replacement
   * @returns {string} The modified byte string
   */
  static replaceBytes(target, source, index) {
    // Remove '0x' prefix if present and convert to lowercase
    const targetClean = target.toLowerCase().startsWith("0x")
      ? target.slice(2).toLowerCase()
      : target.toLowerCase();
    const sourceClean = source.toLowerCase().startsWith("0x")
      ? source.slice(2).toLowerCase()
      : source.toLowerCase();

    // Validate input lengths
    if (sourceClean.length !== 64) {
      // 32 bytes = 64 hex characters
      throw new Error("Source must be exactly 32 bytes long");
    }

    // Convert byte index to hex character index
    const hexIndex = index * 2;

    // Validate target length
    if (targetClean.length < hexIndex + 64) {
      throw new Error("Target too short for replacement at specified index");
    }

    // Perform the replacement
    const prefix = targetClean.slice(0, hexIndex);
    const suffix = targetClean.slice(hexIndex + 64);
    const result = prefix + sourceClean + suffix;

    return "0x" + result;
  }

  static incrementBaddieBytes(input) {
    // Remove '0x' prefix if present and convert to lowercase
    const hex = input.toLowerCase().startsWith("0x")
      ? input.slice(2).toLowerCase()
      : input.toLowerCase();

    // Validate the input ends with 'badd1eb00b135'
    if (!hex.endsWith("badd1eb00b135")) {
      throw new Error("Input must end with badd1eb00b135");
    }

    // Get the position where badd1eb00b135 starts
    const suffix = "badd1eb00b135";
    const prefixLength = hex.length - suffix.length;

    // Extract the prefix (the part before badd1eb00b135)
    let prefix = hex.slice(0, prefixLength);

    // Convert prefix to bigint and increment
    let prefixNum = ethers.getBigInt("0x" + prefix);
    prefixNum = prefixNum + ethers.getBigInt("1");

    // Convert back to hex string, remove '0x' prefix, and pad with zeros
    prefix = prefixNum.toString(16).padStart(prefixLength, "0");

    // Combine everything back together
    return "0x" + prefix + suffix;
  }
}

module.exports = Helpers;
